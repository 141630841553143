<template>
    <span class="countdown" style="color: #999999;">{{ countdown }}</span>
</template>
<script>
export default {
    data() {
        return {
            countdown: ''
        };
    },
    props: {
        createTime: {
            type: Number,
            required: true
        },
        endTime: {
            type: Number,
            required: true
        }
    },
    mounted() {
        this.updateCountdown();
        setInterval(() => {
            this.updateCountdown();
        }, 1000);
    },
    methods: {
        updateCountdown() {
            // const now = Math.floor(Date.now() / 1000); // 当前时间的秒级时间戳
            // const end = this.endTime;
            // const distance = end - now;

            // if (distance < 0) {
            //     this.countdown = '已结束';
            // } else {
            //     const hours = Math.floor(distance / 3600);
            //     const minutes = Math.floor((distance % 3600) / 60);
            //     const seconds = distance % 60;

            //     this.countdown = `${hours} 小时 ${minutes} 分钟 ${seconds} 秒`;
            // }

            const now = Math.floor(Date.now() / 1000); // 当前时间的秒级时间戳
            const end = this.endTime;
            const remainingSeconds = end - now;

            if (remainingSeconds < 0) {
                this.countdown = 0;
            } else {
                this.countdown = remainingSeconds;
            }
        }
    }
};
</script>
